import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './AgriloProf.css';
import agriloIconTop from '../assets/agriloIcons/AgriloTop.png';
import axios from 'axios';
import AgriloProfNavagatorBar from './components/AgriloProfNavagatorBar';
import AgriloProfEventPage from './AgriloProfEventPage';
import AgriloProfUserPage from './AgriloProfUserPage';

const AgriloProf = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('event');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [access, setAccess] = useState(null);
    const [error, setError] = useState('');
    const [prefill, setPrefill] = useState(null);
    const handleIconClicked = () => {
        navigate('/');
    }
    useEffect(() => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('access=')) {
                // Extract the cookie value
                var accessToken = cookie.substring('access='.length);
                setAccess(accessToken);
            }
        }
        if (!accessToken) {
            navigate('/login');
        } else {
            axios.get('https://www.pimasens.com/api/getUser/', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                        navigate('/login');
                    } else {
                        document.cookie = "Username=" + data.Username + ";path=/";
                        if (data.UserType != "agriloUserProf") {
                            navigate('/agrilo');
                        }
                    }
                    setLoading(false); // Set loading to false after data is fetched
                })
                .catch(error => {
                    setError('An error occurred');
                    setLoading(false); // Set loading to false even if there's an error
                });
            }

        axios.get('https://pimasens.com/api/agriloTest/prefill/', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No tests found") {
                    setError('An error occurred');
                    navigate('/login');
                } else {
                    console.log(data)

                    setPrefill(data)
                }
                setLoading2(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                setError('An error occurred');
                setLoading2(false); // Set loading to false even if there's an error
            });

    }, []);

    return (
        <div className="AgriloProf">
            {loading && loading2 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>

                    <div className='AgriloProf-icon' onClick={() => handleIconClicked()}>
                        <img src={agriloIconTop} alt="shot1" className="agriloIconTop" />
                        <h1 className='AgriloProf-h1'>AgriloProf</h1>
                    </div>
                    <AgriloProfNavagatorBar onMenuItemSelect={setSelectedMenuItem} />
                    {selectedMenuItem === 'event' && <AgriloProfEventPage access={access} prefill={prefill} />}
                    {selectedMenuItem === 'user' && <AgriloProfUserPage access={access} prefill={prefill} />}
                </>
            )}
        </div>

    );

};



export default AgriloProf;
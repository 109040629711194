import React, { useState, useEffect } from 'react';
import './AgriloProfUserPage.css';
import axios from 'axios';
import AgriloProfUsersBox from './components/AgriloProfUsersBox';
import AgriloProfAssignedUsersBox from './components/AgriloProfAssignedUsersBox';
import AgriloOverviewBox from '../components/AgriloOverviewBox';
import AgriloTestsBox from '../components/AgriloTestsBox';
import AgriloTestPreviewBox from '../components/AgriloTestPreviewBox';
import AgriloPinnedBox from '../components/AgriloPinnedBox';
import AgriloProfUserAverageBox from './components/AgriloProfUserAverageBox';
import AgriloProfUsersOverviewBox from './components/AgriloProfUsersOverviewBox';
import AgriloProfUserTestsBox from './components/AgriloProfUserTestsBox';

const AgriloProfUserPage = ({access, prefill}) => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [selectedTest, setSelectedTest] = useState(null);
    const [tests, setTests] = useState([]);
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/agriloEvent/getAgriloConnection/?Type=agronomist', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                console.log(access.access)
                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    setUsers(data);
                }
                setLoading1(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading1(false);
            });
    }, []);



    const [selectedUsers, setSelectedUsers] = useState(users[0]);
    const [userOverview, setUserOverview] = useState(false)

    const onBackClick = () => {
        setUserOverview(false)
    };

    return (
        <div className="AgriloProfUserPage">
            <h1 className='AgriloProfUserPage-h1'> Users</h1>
            <div className='AgriloProfUserPage-line1' />
            {loading1 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    {!userOverview && (
                        <AgriloProfAssignedUsersBox users={users}
                            selectedUsers={selectedUsers}
                            onUserSelect={setSelectedUsers}
                            selectOverview={setUserOverview}
                        />
                    )}
                    {userOverview && (
                        <div>
                            <button className='AgriloProfUserPage-back-btn' onClick={() => onBackClick()}>back</button>
                            <div className='Agrilo-HistoryPage-test-box'>
                                <AgriloProfUserTestsBox testData={tests}
                                    selectedTest={selectedTest}
                                    access={access}
                                    onTestSelect={setSelectedTest}
                                    prefill={prefill} 
                                    user={selectedUsers}
                                    />
                                <AgriloTestPreviewBox testData={selectedTest} prefill={prefill} />
                            </div>
                            <AgriloProfUsersOverviewBox access={access} prefill={prefill} user={selectedUsers}/>
                            <AgriloProfUserAverageBox access={access} prefill={prefill} user={selectedUsers}/>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AgriloProfUserPage;

import './AgriloProfUsersOverviewBox.css';
import AgriloPlaceholder from '../assets/agriloIcons/AgriloPlaceholder.png';
import OverviewIcon from '../assets/agriloIcons/OverviewIcon.png';
import AgriloProfUsersOverviewDisplay from './AgriloProfUsersOverviewDisplay';
import React, { useState, useEffect } from 'react';
import { PiGearSixLight } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import axios from 'axios';
import { el } from 'react-day-picker/locale';
const AgriloProfUsersOverviewBox = ({ access, prefill, user }) => {
    const [selectedSeverity, setSelectedSeverity] = useState('');
    const [username, setUsername] = useState(null);
    const [option, setOption] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [chemicals, setChemicals] = useState([])
    const [currentChemical, setCurrentChemical] = useState([]);
    const [averages1, setAverages1] = useState([]);
    const [averages2, setAverages2] = useState([]);
    const addChemical = (newChemical) => {
        setChemicals([...chemicals, newChemical]);
    };

    const removeChemical = (chemicalIndex) => {
        const updatedChemicals = [...chemicals];
        updatedChemicals.splice(chemicalIndex, 1);
        setChemicals(updatedChemicals);
    };

    const handleOptionClicked = () => {
        setOption(!option)
        setCurrentChemical(chemicals);
    };

    const handleCancelClicked = () => {
        setOption(false);
        setChemicals(currentChemical);
    };

    const handleSaveClicked = () => {
        setOption(false);
        if (chemicals != currentChemical) {
            let dataToSend = {
                Type: "AgriloProfWebOverviewBox"+user.AgronomistConnectionID
                , BoxPref: chemicals
            };
            axios.put('https://pimasens.com/api/user/AgriloWebAppPref/', dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                    } else {
                        setAverages1(data.BoxPref);
                        setAverages2(data.BoxPref);
                    }
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    };

    const handleAddClicked = () => {
        addChemical({ SpecID: 1, CropTypeID: 1, VariantID: 1 });
    };

    const updateItem = (index, key, value) => {
        const updatedChemicals = [...chemicals];
        updatedChemicals[index][key] = parseInt(value);
        setChemicals(updatedChemicals);
    };

    const handleItemChange = (event, name, index) => {
        updateItem(index, name, event.target.value);
    };

    const handleRemoveClicked = (index) => {
        removeChemical(index)
    };


    useEffect(() => {
        axios.get('https://pimasens.com/api/user/AgriloWebAppPref/?type=AgriloProfWebOverviewBox'+user.AgronomistConnectionID, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No existing pref") {
                    setError('An error occurred');
                } else {
                    setAverages1(data.BoxPref);
                    setAverages2(data.BoxPref);
                    setChemicals(data.BoxPref);
                    setLoading(false);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
        if (loading === true) {
            let dataToSend = { Type: "AgriloProfWebOverviewBox"+user.AgronomistConnectionID, BoxPref: [] };
            axios.post('https://pimasens.com/api/user/AgriloWebAppPref/', dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        setError('An error occurred');
                    } else {
                        setAverages1(data.BoxPref);
                        setAverages2(data.BoxPref);
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    }, []);

    return (
        <div className="AgriloProfUsersOverviewBox">
            <img src={OverviewIcon} alt="shot1" className="AgriloProfUsersOverviewBox-icon" />
            <h1 className='AgriloProfUsersOverviewBox-h1'> Overview</h1>
            <PiGearSixLight className='AgriloProfUsersOverviewBox-setting-icon' size={50} onClick={handleOptionClicked} />
            <div className='AgriloProfUsersOverviewBox-line1' />
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <div className='AgriloProfUsersOverviewBox-placeholder'>
                    {averages1 && (
                        <AgriloProfUsersOverviewDisplay averages={averages1} quarter={"Q1"} prefill={prefill} access={access} rangeNum={0} year={"2025"} user={user}/>
                    )}
                    {averages2 && (
                        <AgriloProfUsersOverviewDisplay averages={averages2} quarter={"Q3"} prefill={prefill} access={access} rangeNum={2} year={"2024"} user={user}/>
                    )}
                </div>
            )}
            {option && (
                <div className="AgriloProfUsersOverviewBox-popup-menu">
                    <AiOutlineClose size={30} className='AgriloProfUsersOverviewBox-close-icon' onClick={handleCancelClicked} />
                    <div className='AgriloProfUsersOverviewBox-option-box'>
                        {chemicals[0] && (
                            <div className='AgriloProfUsersOverviewBox-option-div'>
                                {/* <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[0].SpecID} className='AgriloTestsBox-filter-select' onChange={(e) => handleItemChange(e, "SpecID", 0)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[0].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 0)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[0].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 0)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloProfUsersOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(0)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[1] && (
                            <div className='AgriloProfUsersOverviewBox-option-div'>
                                {/* <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[1].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 1)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[1].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 1)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[1].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 1)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloProfUsersOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(1)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[2] && (
                            <div className='AgriloProfUsersOverviewBox-option-div'>
                                {/* <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[2].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 2)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[2].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 2)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[2].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 2)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloProfUsersOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(2)}>Remove</h1>
                            </div>
                        )}
                        {chemicals[3] ? (
                            <div className='AgriloProfUsersOverviewBox-option-div'>
                                {/* <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Specs</h1>
                                    <select value={chemicals[3].SpecID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "SpecID", 3)}>
                                        {prefill.specs.map((spec) => (
                                            <option key={spec.SpecID} value={spec.SpecID}>
                                                {spec.SpecName}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Crops</h1>
                                    <select value={chemicals[3].CropTypeID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "CropTypeID", 3)}>
                                        {prefill.crop_types.map((crop) => (
                                            <option key={crop.CropTypeID} value={crop.CropTypeID}>
                                                {crop.CropName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='AgriloProfUsersOverviewBox-option-picker-box'>
                                    <h1>Chemicals</h1>
                                    <select value={chemicals[3].VariantID} className='AgriloTestsBox-filter-select' onChange={(event) => handleItemChange(event, "VariantID", 3)}>
                                        {prefill.variants.map((chem) => (
                                            <option key={chem.VariantID} value={chem.VariantID}>
                                                {chem.VariantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h1 className='AgriloProfUsersOverviewBox-option-remove-text' onClick={() => handleRemoveClicked(3)}>Remove</h1>
                            </div>
                        ) : (
                            <div className='AgriloProfUsersOverviewBox-option-add' onClick={handleAddClicked}>
                                <CiCirclePlus size={60} />
                                <h1 className='AgriloProfUsersOverviewBox-add-text'>Add test</h1>
                            </div>
                        )}
                        <button className='AgriloProfUsersOverviewBox-save-button' onClick={handleSaveClicked}>Save</button>
                    </div>
                </div>
            )}
            {option && (
                <div className='AgriloProfUsersOverviewBox-overlay'></div>
            )}
        </div>
    );
};

export default AgriloProfUsersOverviewBox;



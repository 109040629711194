import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ZenroProf.css';
import ZenroProfNavagatorBar from './components/ZenroProfNavagatorBar';
import zenroIconTop from './ZenroProfIcon/ZenroIcon.png';
import PatientsPage from './PatientsPage';
import MessagePage from './MessagePage';
import axios from 'axios';
import NavBar from '../components/NavBar';

const ZenroProf = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('patients');
    const location = useLocation();
    const [User, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const cookies = document.cookie.split(';');
        console.log(cookies)
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('access=')) {
                // Extract the cookie value
                var accessToken = cookie.substring('access='.length);
                setAccess(accessToken);
            }
        }
        if (!accessToken) {
            navigate('/login');
        } else {
            axios.get('https://www.pimasens.com/api/getUser/', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    const data = response.data;

                    if (data.error === "No tests found") {
                        setError('An error occurred');
                        navigate('/login');
                    } else {
                        setUser(data);
                        document.cookie = "Username=" + data.Username + ";path=/";
                    }
                    setLoading(false); // Set loading to false after data is fetched
                })
                .catch(error => {
                    setError('An error occurred');
                    setLoading(false); // Set loading to false even if there's an error
                });
        }
    }, [access, navigate]);

    const handleIconClicked = () => {
        navigate('/');
    }

    return (


        <div className="ZenroProf">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    <img src={zenroIconTop} className="zenroProfIconTop" onClick={() => handleIconClicked()} />
                    <ZenroProfNavagatorBar onMenuItemSelect={setSelectedMenuItem} />
                    {selectedMenuItem === 'patients' && <PatientsPage access={access} />}
                    {selectedMenuItem === 'message' && <MessagePage access={access} />}
                    <div className="ZenroProf-nav">
                        {/* <div className='ZenroProf-background-nav' /> */}
                        <NavBar />
                    </div>
                </>
            )}
        </div>
    );

};



export default ZenroProf;
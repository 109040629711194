import React, { useState, useEffect } from 'react';
import './AgriloSummaryPage.css';
import AgriloCalendar from '../components/AgriloCalendar';
import AgriloTaskBox from '../components/AgriloTaskBox';
import AgriloPinnedBox from '../components/AgriloPinnedBox';
import AgriloTaskBar from '../components/AgriloTaskBar';
import axios from 'axios';


const AgriloSummaryPage = ({access, prefill}) => {
    const [tests, setTests] = useState([])
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [eventDay, setEventDay] = useState('')
    useEffect(() => {
        axios.get('https://pimasens.com/api/user/agriloTest/', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error) {
                    setError('An error occurred');
                    setTests([]);
                } else {
                    setTests(data);
                }
                setLoading(false);
            })
            .catch(error => {
                setError('An error occurred');
            });
    }, []);

    //     {
    //         eventID: 1,
    //         Name: "Full Nutrient Ammendment",
    //         StartDate: "2024-10-01",
    //         EndDate: "2024-10-07",
    //         Color: "lightblue",
    //         Description: "Check and apply required nutrient amendments to the soil. Ensure all amendments are properly documented for future reference"
    //     },
    //     {
    //         eventID: 2,
    //         Name: "Nitrate Soil Test",
    //         StartDate: "2024-10-10",
    //         EndDate: "2024-10-15",
    //         Color: "orange",
    //         Description: "Fake stuff blah blahj"
    //     },
    //     {
    //         eventID: 3,
    //         Name: "Nutrient Ammendment",
    //         StartDate: "2024-10-18",
    //         EndDate: "2024-10-18",
    //         Color: "lightgreen",
    //         Description: "DIFERENT LENGAGGHGHG hijsdgnbkjan ijgajl agjkasnjkgnag "
    //     },
    //     {
    //         eventID: 4,
    //         Name: "Demostraction",
    //         StartDate: "2024-11-25",
    //         EndDate: "2024-12-05",
    //         Color: "pink",
    //         Description: "Different descriptions wow "
    //     },
    //     {
    //         eventID: 5,
    //         Name: "more dummy",
    //         StartDate: "2024-12-18",
    //         EndDate: "2024-12-25",
    //         Color: "lightblue",
    //         Description: "Different descriptions wow "
    //     },
    // ]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);

    const handleTaskFilter = (filtered) => {
        setFilteredTasks(filtered);
    };

    const handleEventFilter = (filtered) => {
        setFilteredEvents(filtered);
    }

    return (
        <div className="Agrilo-SummaryPage">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
            <h1 className='Agrilo-SummaryPage-h1'> Summary</h1>
            <div className='Agrilo-SummaryPage-line1' />
            <AgriloTaskBar tests={filteredTasks} prefill={prefill} events={filteredEvents} eventDay={eventDay}/>
            <AgriloCalendar task={tests} onTaskFilter={handleTaskFilter} 
            setTasks={setTests} access={access}
            setEvents={setEvents} onEventFilter={handleEventFilter} event={events} setEventDay={setEventDay}/>
            {/* <AgriloTaskBox task={filteredTasks} /> */}
            <AgriloPinnedBox access={access} prefill={prefill}/>
            </>
        )}
        </div>
    );
};

export default AgriloSummaryPage;
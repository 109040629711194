import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AgriloProfTimeBox.css';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import classNames from "react-day-picker/style.module.css";


const AgriloProfTimeBox = ({ info, setInfo }) => {
    const [inputValue, setInputValue] = useState('');
    const [showToPicker, setToPicker] = useState(false);
    const [showFromPicker, setFromPicker] = useState(false);
    const [showEndPicker, setEndPicker] = useState(false);
    const [selectedDayTo, setSelectedDayTo] = useState('')
    const [selectedDayFrom, setSelectedDayFrom] = useState('')
    const [selectedDayEnd, setSelectedDayEnd] = useState('')


    const onResetClick = () => {
        setInfo({ ...info, startDate: '', endDate: '', untilDate: '', repeat: false, frequency: '1', frequencyType: 'week' });
    }

    const handleToDayClick = (day) => {
        setSelectedDayTo(day);
        setInfo({ ...info, endDate: format(day, 'yyyy-MM-dd') });
    };


    const handleOKClickTo = () => {
        if (selectedDayTo != '') {
            setInfo({ ...info, endDate: format(selectedDayTo, 'yyyy-MM-dd') });
        }
        setToPicker(false);
    };

    const handleFromDayClick = (day) => {
        setSelectedDayFrom(day);
        setInfo({ ...info, startDate: format(day, 'yyyy-MM-dd') });
    };


    const handleOKClickFrom = () => {
        if (selectedDayFrom != '') {
            setInfo({ ...info, startDate: format(selectedDayFrom, 'yyyy-MM-dd') });
        }
        setFromPicker(false);
    };

    const handleEndDayClick = (day) => {
        setSelectedDayEnd(day);
        setInfo({ ...info, untilDate: format(day, 'yyyy-MM-dd') });
    };

    const handleOKClickEnd = () => {
        if (selectedDayEnd != '') {
            setInfo({ ...info, untilDate: format(selectedDayEnd, 'yyyy-MM-dd') });
        }
        setEndPicker(false);
    }


    return (
        <div className="AgriloProfTimeBox">
            <img src={CalendarIcon} alt="shot1" className="AgriloProfEventBox-icon" />
            <h1 className='AgriloProfEventBox-h1'>Time</h1>
            <div className='AgriloProfTimeBox-line1' />
            <div className='AgriloProfTimeBox-container'>
                <div className='AgriloProfTimeBox-date'>
                    <h1 className='AgriloProfTimeBox-label'>Date</h1>
                    <div className='AgriloProfTimeBox-date-container'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <input
                                className='AgriloProfTimeBox-from'
                                type="text"
                                value={info.startDate}
                                readOnly
                                onClick={() => setFromPicker(true)}
                            />
                            {
                                showFromPicker && (
                                    <div className='AgriloTestsBox-filter-daypicker'>
                                        <DayPicker
                                            classNames={classNames}
                                            captionLayout="dropdown"
                                            selected={selectedDayFrom}
                                            onDayClick={handleFromDayClick}
                                            mode="single" // Changed to "single"
                                        />
                                        <button onClick={handleOKClickFrom}>OK</button>
                                    </div>
                                )
                            }
                            {
                                showFromPicker && (
                                    <div className='AgriloOverviewBox-overlay'></div>
                                )
                            }
                        </div >
                        <h2 className='AgriloProfTimeBox-small-label'>to</h2>
                        <input
                            className='AgriloProfTimeBox-to'
                            type="text"
                            value={info.endDate}
                            readOnly
                            onClick={() => setToPicker(true)}
                        />
                        {
                            showToPicker && (
                                <div className='AgriloTestsBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayTo}
                                        onDayClick={handleToDayClick}
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickTo}>OK</button>
                                </div>
                            )
                        }
                        {
                            showToPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )
                        }
                    </div>
                </div>
                <div className='AgriloProfTimeBox-frequency'>
                    <h1 className='AgriloProfTimeBox-label'>Frequency</h1>
                    <div className='AgriloProfTimeBox-every-container'>
                        <div className='AgriloProfTimeBox-one-time'>
                            <label className='AgriloProfTimeBox-small-label'>
                                <input type='radio' name='time-input' className='AgriloProfTimeBox-input-one-time' checked={info.repeat === false} onClick={() => setInfo({ ...info, repeat: false })}/>
                                One Time
                            </label>
                        </div>
                        <div className='AgriloProfTimeBox-every'>
                            <label className='AgriloProfTimeBox-small-label'>
                                <input type='radio' name='time-input' className='AgriloProfTimeBox-input-every' checked={info.repeat === true} onClick={() => setInfo({ ...info, repeat: true})}/>
                                Every
                            </label>
                            <select className='AgriloProfTimeBox-select-num' value={info.frequency} onChange={(e) => setInfo({ ...info, frequency: e.target.value })}>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                            </select>
                            <select className='AgriloProfTimeBox-select-unit' value={info.frequencyType} onChange={(e) => setInfo({ ...info, frequencyType: e.target.value })}>
                                <option value='week'>Week</option>
                                <option value='month'>Month</option>
                                <option value='year'>Year</option>
                            </select>
                        </div>
                        <div className='AgriloProfTimeBox-until'>
                            <h2 className='AgriloProfTimeBox-small-label'>Until</h2>
                            <input
                                className='AgriloProfTimeBox-to'
                                type="text"
                                value={info.untilDate}
                                readOnly
                                onClick={() => setEndPicker(true)}
                            />
                            {
                                showEndPicker && (
                                    <div className='AgriloTestsBox-filter-daypicker'>
                                        <DayPicker
                                            classNames={classNames}
                                            captionLayout="dropdown"
                                            selected={selectedDayEnd}
                                            onDayClick={handleEndDayClick}
                                            mode="single" // Changed to "single"
                                        />
                                        <button onClick={handleOKClickEnd}>OK</button>
                                    </div>
                                )
                            }
                            {
                                showEndPicker && (
                                    <div className='AgriloOverviewBox-overlay'></div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <button className='AgriloProfTimeBox-reset' onClick={onResetClick}>Reset</button>
                {/* <button className='AgriloProfTimeBox-save'>Save</button> */}
            </div>
        </div>
    );
};

export default AgriloProfTimeBox;

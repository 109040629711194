import React, { useState, useEffect } from 'react';
import './AgriloOverviewDisplay.css';
import axios from 'axios';

const AgriloOverviewDisplay = ({ prefill, averages, quarter, access, rangeNum, year }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState('');
    const range = ['JAN-MAR', 'APR-JUN', 'JUL-AUG', 'SEP-DEC']
    useEffect(() => {
        if (averages.length == 0) {
            setData([])
        } else {
            var ids = ""
            for (var i = 0; i < averages.length; i++) {
                ids += averages[i].CropTypeID + "," + averages[i].VariantID
                if (i != averages.length - 1) {
                    ids += ";"
                }
            }

            axios.get('https://pimasens.com/api/agriloTest/getQuarterAverage/?Year=' + year + '&Quarter=' + quarter + '&CropChemicalPairs=' + ids, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    setData(data);
                })
                .catch(error => {
                    setError('An error occurred');
                });
        }
    }, [averages]);
    return (
        <div className="AgriloOverviewDisplay">
            {/* the right bar */}
            <h1 className='AgriloOverviewDisplay-title'>{quarter}</h1>
            <div className='AgriloOverviewDisplay-bar'>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-high'>High</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-prime' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-optimal'>Optimal</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-prime' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-low'>Low</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
            </div>
            <h1 className='AgriloOverviewDisplay-date'>{range[rangeNum]}</h1>
            <div className='AgriloOverviewDisplay-chemcials-box'>

                {data[0] && (
                    <ChemicalBar
                        ChemicalName={prefill.variants[data[0].VariantID - 1].VariantName}
                        CropName={prefill.crop_types[data[0].CropTypeID - 1].CropName}
                        data={data[0]}
                        prefill={prefill}
                    />
                )}

                {data[1] && (
                    <ChemicalBar
                        ChemicalName={prefill.variants[data[1].VariantID - 1].VariantName}
                        CropName={prefill.crop_types[data[1].CropTypeID - 1].CropName}
                        data={data[1]}
                        prefill={prefill}
                    />
                )}
                {data[2] && (
                    <ChemicalBar
                        ChemicalName={prefill.variants[data[2].VariantID - 1].VariantName}
                        CropName={prefill.crop_types[data[2].CropTypeID - 1].CropName}
                        data={data[2]}
                        prefill={prefill}
                    />
                )}
                {data[3] && (
                    <ChemicalBar
                        ChemicalName={prefill.variants[data[3].VariantID - 1].VariantName}
                        CropName={prefill.crop_types[data[3].CropTypeID - 1].CropName}
                        data={data[3]}
                        prefill={prefill}
                    />
                )}
            </div>
        </div>
    );
};

export default AgriloOverviewDisplay;


function ChemicalBar(props) {
    var testColor;
    const { data, prefill } = props
    var heightPX = 0;
    var percentage = 0;
    var percentage = 0;
    var number = 0;
    var rangeLow = 20;
    var rangeHigh = 50;
    var rangeMax = 100;
    var CropName = prefill.crop_types[data.CropTypeID - 1].CropName
    var ChemicalName = prefill.variants[data.VariantID - 1].VariantName
    if (data.error != undefined) {
        number = 0
    } else {
        number = data.average_test_result.toFixed(3)
        for (var i = 0; i < prefill["ranges"].length; i++) {
            if (prefill["ranges"][i]["VariantID"] == data.VariantID && prefill["ranges"][i]["CropTypeID"] == data.CropTypeID) {
                rangeLow = prefill["ranges"][i]["MinPpm"]
                rangeHigh = prefill["ranges"][i]["MaxPpm"]
                rangeMax = rangeHigh*2
            }
        }
    }
    if (number < rangeLow) {
        percentage = number / rangeLow
        heightPX = percentage * 4.5
        testColor = '#CFBF05'
    } else if (number > rangeHigh) {
        percentage = (number - rangeHigh) / (rangeMax - rangeHigh)
        testColor = '#C83A31'
        if (percentage > 1) {
            heightPX = 15;
        } else { heightPX = percentage * 4.3 + 10.65 }
    } else {
        testColor = '#4C6CFD'
        percentage = (number - rangeLow) / (rangeHigh - rangeLow)
        heightPX = percentage * 4.4 + 5.3
    }
    return (
        <div className='AgriloTestsBox-chemical'>
            <div className='AgriloTestsBox-chemical-name-box'>
                <div className='AgriloTestsBox-chemical-crop-box'>{CropName}</div>
                <div className='AgriloTestsBox-chemical-chem-box'>{ChemicalName}</div>
            </div>
            <div className='AgriloTestsBox-chemical-bar' style={{ height: heightPX.toString() + "rem" }} />
            <div className='AgriloTestsBox-chemical-dot' style={{ backgroundColor: testColor, bottom: (heightPX + 2).toString() + "rem" }} />
            <h1 className='AgriloTestsBox-chemical-result' style={{ color: testColor, bottom: (heightPX + 2).toString() + "rem" }}>{number}</h1>
            <h1 className='AgriloTestsBox-chemical-metric' style={{ bottom: (heightPX + 2.25).toString() + "rem" }}>ppm</h1>
        </div>
    );
}
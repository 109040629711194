import React, { useState, useEffect } from 'react';
import './AgriloTestPreviewBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import placeholder from '../assets/agriloIcons/AgriloPlaceholder2.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AgriloTestPreviewBox = ({ testData, prefill }) => {
  const color = ['#CFBF05', '#4C6CFD', '#C83A31']
  const recommendation = ['Low', 'Optimal', 'High']
  const [rating, setRating] = useState(1);
  const [max, setMax] = useState(1);
  const [min, setMin] = useState(0);
  useEffect(() => {
    if (testData != null) {
      if (testData.TestRangeResult == "high") {
        setRating(2)
      } else if (testData.TestRangeResult == "low") {
        setRating(0)
      }
    }

    if (testData != null && prefill != null) {
      for (var i = 0; i < prefill["ranges"].length; i++) {
        if (prefill["ranges"][i]["VariantID"] == testData.VariantID && prefill["ranges"][i]["CropTypeID"] == testData.CropTypeID) {
          setMax(prefill["ranges"][i]["MaxPpm"])
          setMin(prefill["ranges"][i]["MinPpm"])
          console.log("DAN")
        }
      }
    }

  }, [testData]);
  return (
    <div className="AgriloTestPreviewBox">
      <img src={TestsIcon} alt="shot1" className="AgriloTestPreviewBox-icon" />
      <h1 className='AgriloTestPreviewBox-h1'>Test Preview</h1>
      <div className='AgriloTestPreviewBox-line1' />
      <h2 className='AgriloTestPreviewBox-h2'>{testData != null ? prefill["specs"]["0"]["SpecName"] : "null test"} </h2>
      <h3 className='AgriloTestPreviewBox-h3'>
        {testData != null ?
          new Date(testData.TimeCreated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }) : "null test"} </h3>
      <div className='AgriloTestPreviewBox-placeholder' >
        <div style={{ width: '15rem', height: '15rem', position: 'relative' }}>
          <CircularProgressbar strokeWidth={5} value={testData != null ? testData.PpmRecorded : "0"} circleRatio={0.75} maxValue={max} minValue={min} text={testData != null ? testData.PpmRecorded.toFixed(2) : "0"}
            styles={buildStyles({
              pathColor: color[rating],
              fontWeight: '500',
              fontFamily: 'Poppins',
              textSize: '1rem',
              rotation: 1 / 2 + 1 / 8,
              trailColor: "#eee",
              classes: "AgriloTestPreviewBox-value-text",
              textColor: color[rating],
            })} />
        </div>
        <h1 className='AgriloTestPreviewBox-chemical'>{testData != null ? prefill["variants"][testData.VariantID - 1]["VariantName"] : "0"}</h1>
        <h1 className='AgriloTestPreviewBox-metric'>ppm</h1>
        <h1 className='AgriloTestPreviewBox-range-low'>{min}</h1>
        <h1 className='AgriloTestPreviewBox-range-high'>{max}</h1>
        <div className='AgriloTestPreviewBox-rating' style={{ backgroundColor: color[rating] }}>
          <h1 className='AgriloTestPreviewBox-rating-text'>{recommendation[rating]}</h1>
        </div>
        <h1 className='AgriloTestPreviewBox-range'>20-40ppm</h1>
      </div>
      <h4 className='AgriloTestPreviewBox-h4'>Recommendation</h4>
      <h5 className='AgriloTestPreviewBox-h5'>{testData != null ? testData.Desc : "no test"}</h5>
    </div>
  );
};

export default AgriloTestPreviewBox;

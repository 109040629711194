import React, { useState, useEffect } from 'react';
import './AgriloTestsBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import AgriloRecentTestBox from './AgriloRecentTestBox';
import { IoFilterSharp } from "react-icons/io5";
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import axios from 'axios';
const AgriloTestsBox = ({ testData, selectedTest, onTestSelect, access, prefill }) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']
    const [filterBox, setFilterBox] = useState(false)
    const [givenTest, setTests] = useState([])
    const [inputFrom, setInputFrom] = useState('');
    const [inputTo, setInputTo] = useState('');
    const [error, setError] = useState('');
    const [cropType, setCropType] = useState('All');
    const [chemicalType, setChemicalType] = useState('All');
    const [specType, setSpecType] = useState('All');
    const [showFromPicker, setFromPicker] = useState(false);
    const [showToPicker, setToPicker] = useState(false);
    const [selectedDayTo, setSelectedDayTo] = useState('')
    const [selectedDayFrom, setSelectedDayFrom] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [inputValue, setInputValue] = useState('');
    const handleItemClick = (test) => {
        if (selectedTest !== test) { // Only update if a different test is clicked
            onTestSelect(test);
        }
    };

    const ratingAssign = (result) => {
        if (result == "high") {
            return 2
        } else if (result == "low") {
            return 0
        } else {
            return 1
        }
    }

    const handleFilterClick = () => {
        setFilterBox(!filterBox)
    }

    const handleCropTypeChange = (event) => {
        setCropType(event.target.value);
    }

    const handleChemicalTypeChange = (event) => {
        setChemicalType(event.target.value);
    }

    const handleSpecTypeChange = (event) => {
        setSpecType(event.target.value);
    }

    const onResetClick = () => {
        setInputFrom('');
        setInputTo('');
        setCropType('All');
        setChemicalType('All');
        setSpecType('All');
    }



    const handleToDayClick = (day) => {
        setSelectedDayTo(day);
        setInputTo(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickTo = () => {
        if (selectedDayTo != '') {
            setInputTo(format(selectedDayTo, 'yyyy-MM-dd'));
        }
        setToPicker(false);
    };

    const handleFromDayClick = (day) => {
        setSelectedDayFrom(day);
        setInputFrom(format(day, 'yyyy-MM-dd'));
    };


    const handleOKClickFrom = () => {
        if (selectedDayFrom != '') {
            setInputFrom(format(selectedDayFrom, 'yyyy-MM-dd'));
        }
        setFromPicker(false);
    };

    const onFilterClicked = () => {
        var hasFields = false
        var fields = ''
        if (inputFrom != '') {
            fields += hasFields ? `&StartDate=${inputFrom}` : `?StartDate=${inputFrom}`;
            hasFields = true
        }
        if (inputTo != '') {
            fields += hasFields ? `&EndDate=${inputTo}` : `?EndDate=${inputTo}`;
            hasFields = true
        }
        if (cropType != 'All') {
            fields += hasFields ? `&CropID=${cropType}` : `?CropID=${cropType}`;
            hasFields = true
        }
        if (chemicalType != 'All') {
            fields += hasFields ? `&VariantID=${chemicalType}` : `?VariantID=${chemicalType}`;
            hasFields = true
        }

        axios.get('https://www.pimasens.com/api/agriloTest/getFilter/' + fields, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error == "No tests found") {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests)
                }
            })
            .catch(error => {
                setError('An error occurred');
            });

    }

    useEffect(() => {
        if (testData.length > 0) {
            setTests(testData)
        }
    }, [testData])



    return (
        <div className="AgriloTestsBox">
            <div className='AgriloTestBox-header'>
                <img src={TestsIcon} alt="shot1" className="AgriloTestsBox-icon" />
                <h1 className='AgriloTestsBox-h1'> Tests</h1>
            </div>
            <div className='AgriloTestsBox-line1' />
            <h2 className='AgriloTestsBox-h2 recent'>Recent</h2>
            <div className='AgriloTestsBox-recent-test'>
                {testData[0] && (
                    <AgriloRecentTestBox test={testData[0]} prefill={prefill} />
                )}
                {testData[1] && (
                    <AgriloRecentTestBox test={testData[1]} prefill={prefill} />
                )}
            </div>
            <div className='AgriloTestsBox-box-filter-button' >
                <h2 className='AgriloTestsBox-h2 all'>All</h2>
                <button className='AgriloTestsBox-filter-button' onClick={handleFilterClick}><IoFilterSharp />Filter</button>
            </div>
            <div className='AgriloTestsBox-line2' />
            {filterBox && (
                <div className='AgriloTestsBox-filter-box'>
                    <div className='AgriloProfTimeBox-date-container'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloTestsBox-filter-label'>From</label>
                            <input
                                className='AgriloTestsBox-filter-box-to'
                                type="text"
                                value={inputFrom}
                                readOnly
                                onClick={() => setFromPicker(true)}
                            />
                            {showFromPicker && (
                                <div className='AgriloTestsBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayFrom}
                                        onDayClick={handleFromDayClick}
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickFrom}>OK</button>
                                </div>
                            )}
                            {showFromPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='AgriloTestsBox-filter-label'>To</label>
                            <input
                                className='AgriloTestsBox-filter-box-to'
                                type="text"
                                value={inputTo}
                                readOnly
                                onClick={() => setToPicker(true)}
                            />
                            {showToPicker && (
                                <div className='AgriloTestsBox-filter-daypicker'>
                                    <DayPicker
                                        classNames={classNames}
                                        captionLayout="dropdown"
                                        selected={selectedDayTo}
                                        onDayClick={handleToDayClick} // Keep the same handler
                                        mode="single" // Changed to "single"
                                    />
                                    <button onClick={handleOKClickTo}>OK</button>
                                </div>
                            )}
                            {showToPicker && (
                                <div className='AgriloOverviewBox-overlay'></div>
                            )}
                        </div>
                    </div>
                    <div className='AgriloTestsBox-filter-box-select'>
                        <label className='AgriloTestsBox-filter-label'>Crop Type:</label>
                        <select value={cropType} className='AgriloTestsBox-filter-select' onChange={handleCropTypeChange}>
                            <option value="All">All</option>
                            {prefill["crop_types"].map(crop => (
                                <option value={crop.CropTypeID}>{crop.CropName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='AgriloTestsBox-filter-box-select'>
                        <label className='AgriloTestsBox-filter-label'>Chemical Type:</label>
                        <select value={chemicalType} className='AgriloTestsBox-filter-select' onChange={handleChemicalTypeChange}>
                            <option value="All">All</option>
                            {prefill["variants"].map(chemical => (
                                <option value={chemical.VariantID}>{chemical.VariantName}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className='AgriloTestsBox-filter-box-select'>
                        <label className='AgriloTestsBox-filter-label'>Spec Type</label>
                        <select value={specType} className='AgriloTestsBox-filter-select' onChange={handleSpecTypeChange}>
                            <option value="All">All</option>
                            {prefill["specs"].map(spec => (
                                <option value={spec.SpecName}>{spec.SpecName}</option>
                            ))}
                        </select>
                    </div> */}
                    {/* <div className='AgriloTestsBox-filter-box-select'>
                        <label className='AgriloTestsBox-filter-label'>Rating</label>
                        <select className='AgriloTestsBox-filter-select'>
                            <option value="All">All</option>
                            <option value="Good">Good</option>
                            <option value="Average">Average</option>
                            <option value="Bad">Bad</option>
                        </select>
                    </div> */}
                    <div className='AgriloTestsBox-filter-box-buttons'>
                        <button className='AgriloTestsBox-filter-box-reset' onClick={onResetClick}>Reset</button>
                        <button className='AgriloTestsBox-filter-box-filter' onClick={onFilterClicked}>Filter</button>
                    </div>
                </div>
            )}
            {/* list of test container */}
            <div className="AgriloTestsBox-item-container">
                <nav className="AgriloTestsBox-items">

                    <ul className='AgriloTestsBox-ul'>
                        {givenTest.slice().map(test => (
                            <li
                                key={test.TestID}
                                className={`AgriloTestsBox-test-item ${selectedTest === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                {/* <span className="AgriloTestsBox-item-name">{prefill["specs"]["0"]["SpecName"]}</span> */}
                                <span className="AgriloTestsBox-item-date">{new Date(test.TimeCreated).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                                <span className="AgriloTestsBox-plant-type">
                                    {prefill["crop_types"][test.CropTypeID - 1]["CropName"]}
                                </span>
                                <span className="AgriloTestsBox-chemical-type">
                                    {prefill["variants"][test.VariantID - 1]["VariantName"]}
                                </span>
                                <div className='AgriloTestsBox-unit-amount-box'>
                                    <span className="AgriloTestsBox-unit-amount" style={{ color: testColor[ratingAssign(test.TestRangeResult)] }} >{test.PpmRecorded.toFixed(3)}</span>
                                    <span className="AgriloTestsBox-units">PPM</span>
                                </div>


                                {/* <span className="AgriloTestsBox-item-name">{test.name}</span> */}

                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

        </div>
    );
};

export default AgriloTestsBox;


import React, { useState, useEffect } from 'react';
import './AgriloProfEventBar.css';

const AgriloProfEventBar = ({ info, user }) => {
    const [users, setUsers] = useState([]);
    const DateConverter = (startDate, endDate) => {
        if (startDate === '' || endDate === '') {
            return '';
        }
        if (startDate !== '' && endDate === '') {
            return startDate;
        }
        if (startDate === '' && endDate !== '') {
            return endDate;
        }
        const start = new Date(startDate + 'T12:00:00Z');
        const end = new Date(endDate + 'T12:00:00Z');

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const startMonth = monthNames[start.getMonth()];
        const startDay = start.getDate();
        const endMonth = monthNames[end.getMonth()];
        const endDay = end.getDate();

        if (start.toDateString() === end.toDateString()) {
            return `${startMonth} ${startDay}, ${start.getFullYear()}`;
        } else if (startMonth === endMonth) {
            return `${startMonth} ${startDay}-${endDay}, ${start.getFullYear()}`;
        } else {
            return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${start.getFullYear()}`;
        }
    }
    console.log(user)
    useEffect(() => {
        if (user != null) {
            setUsers(user);
        }
    }, [user]);



    return (
        <div className="AgriloProfEventBar">
            <div className='AgriloProfEventBar-event-start'>
                <h1 className='AgriloProfEventBar-event-text'>Set Reccomendation</h1>
            </div>
            <div className='AgriloProfEventBar-event-info'>
                <h1 className='AgriloProfEventBar-event-title'>{info.title}</h1>
                <h2 className='AgriloProfEventBar-event-date'>
                    {DateConverter(info.startDate, info.endDate)}
                </h2>
                <h2 className='AgriloProfEventBar-event-frequency'>
                    {/* only display if repeat is true */}
                    {info.repeat && `Every ${info.frequency} ${info.frequencyType}`}
                </h2>
                <h2 className='AgriloProfEventBar-event-until'>
                    {/* only display if repeat is true */}
                    {info.repeat && `Until ${info.untilDate}`}
                </h2>
            </div>
            <div className='AgriloProfEventBar-event-description-box'>
                <h2 className='AgriloProfEventBar-event-description label'>Description</h2>
                <h2 className='AgriloProfEventBar-event-description'>
                    {info.description}
                </h2>
            </div>
            <div className='AgriloProfEventBar-event-users-box'>
                <h2 className='AgriloProfEventBar-event-user label'>Users</h2>

                <h2 className='AgriloProfEventBar-event-user'>
                    {info.user}
                </h2>


            </div>

        </div>
    );
};

export default AgriloProfEventBar;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './AgriloProfUsersBox.css';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import { el } from 'react-day-picker/locale';

const AgriloProfUsersBox = ({ access, info, setInfo }) => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // For search input
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [error, setError] = useState('');
    const searchInputRef = useRef(null);
    const suggestionsRef = useRef(null);

    useEffect(() => {
        axios.get('https://www.pimasens.com/api/agriloEvent/getAgriloConnection/?Type=agronomist', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No users found") {
                    setError('An error occurred');
                } else {
                    setUsers(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }, [access]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setShowSuggestions(true);
    };

    const handleUserClick = (user) => {
        setSelectedUsers(user);

        setShowSuggestions(false);
        setInfo({ ...info, user: user.user.FirstName + " " + user.user.LastName });
    };

    const handleRemoveClick = () => {
        setSelectedUsers(null);
        setSearchTerm(''); // Clear input
        setInfo({ ...info, user: '' });
    };

    const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) && searchInputRef.current && !searchInputRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const filteredUsers = users.filter((user) =>
        (user.user.FirstName + " " + user.user.LastName).toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleOnFocus = () => {
        setShowSuggestions(true);
    }

    const onResetClick = () => {
        setSelectedUsers(null);
        setSearchTerm('');
        setInfo({ ...info, user: '' });
    };

    const onSendClick = () => {
        // Check if all required fields are filled
        const requiredFields = ['startDate', 'endDate', 'frequency', 'description', 'user', 'title'];
        const missingFields = [];

        requiredFields.forEach(field => {
            if (!info[field]) {  // Check if the field is empty or null or undefined
                missingFields.push(field);
            }
        });
        if (info.repeat && !info.untilDate) {
            missingFields.push('untilDate');
        }

        if (missingFields.length > 0) {
            // Display an error message to the user
            const errorMessage = `The following fields are required: ${missingFields.join(', ')}`;
            alert(errorMessage);
            return
        }else{
            console.log(info);
            let sendInfo = {
                AgronomistConnectionID: selectedUsers.AgronomistConnectionID,
                StartDate: info.startDate,
                EndDate: info.endDate,
                Desc: info.description,
                Title: info.title,
                Type: 'agronomist',
                Color: 'lightgreen',
                // if repeat is true, send the until date
                UntilDate: info.repeat ? info.untilDate : null,
                Frequency: info.repeat ? info.frequency+info.frequencyType : "0",
                EndFrequency: info.repeat ? info.untilDate : null
            }
            // Send the data to the server
            axios.post('https://www.pimasens.com/api/user/AgriloEvent/', sendInfo, {
                headers: {
                    'Authorization': 'Bearer ' + access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error) {
                        alert("An error occurred try again later");
                    }else{
                        alert("Event created successfully");
                    }
                })
                .catch(error => {
                    alert("An error occurred try again later");
                    setError('An error occurred');
                });
        }
    };

    return (
        <div className="AgriloProfUsersBox">
            <img src={CalendarIcon} alt="shot1" className="AgriloProfEventBox-icon" />
            <h1 className='AgriloProfEventBox-h1'>Users</h1>
            <div className='AgriloProfEventBox-line1' />
            <div className='AgriloProfUsersBox-container'>
                <div className='AgriloProfUsersBox-users'>
                    <h1 className='AgriloProfUsersBox-label'>Users</h1>
                    <input
                        className='AgriloProfUsersBox-input-users'
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onFocus={handleOnFocus}
                        ref={searchInputRef}
                    />
                    {/*<button className='AgriloProfUsersBox-add'>Add</button>  No longer needed */}
                </div>
                {showSuggestions && filteredUsers.length > 0 && (
                    <div className='AgriloProfUsersBox-lookup' style={{ position: "absolute", width: "15rem", height: "fit-content", border: "2px solid black", zIndex: "1", cursor: "pointer", overflow: "auto", backgroundColor: "#F3F3F7" }} ref={suggestionsRef}>
                        <nav className="AgriloProfUsersBox-items">
                            <ul className='AgriloProfUsersBox-ul'>
                                {filteredUsers.map(user => (
                                    <li
                                        key={user.AgronomistConnectionID}
                                        className={`AgriloProfUsersBox-user-item`}
                                        onClick={() => handleUserClick(user)}
                                    >
                                        <span className="AgriloProfUsersBox-user-send">{user.user.FirstName + " " + user.user.LastName}</span>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                )}
                {showSuggestions && filteredUsers.length === 0 && (
                    <div className='AgriloProfUsersBox-lookup' style={{ position: "absolute", background: "#F3F3F7", width: "15rem", height: "fit-content", border: "1px solid black", zIndex: "1", cursor: "pointer" }} ref={suggestionsRef}>
                        <nav className="AgriloProfUsersBox-items">
                            <ul className='AgriloProfUsersBox-ul'>
                                <li>No users found.</li>
                            </ul>
                        </nav>
                    </div>
                )}


                {selectedUsers && (
                    <div className='AgriloProfUsersBox-user-box' onClick={handleRemoveClick}>
                        <h1 className='AgriloProfUsersBox-user-selected'>
                            {selectedUsers.user.FirstName + " " + selectedUsers.user.LastName}
                        </h1>
                    </div>
                )}
                <button className='AgriloProfUsersBox-reset' onClick={onResetClick}>Reset</button>
                <button className='AgriloProfUsersBox-send' onClick={onSendClick}>Send</button>
            </div>
        </div>
    );
};

export default AgriloProfUsersBox;
import './ActivateAccountPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route, useParams } from 'react-router-dom';
import axios from 'axios';

const ActivateAccountPage = () => {
    const { token } = useParams();
    const [showSuccess, setShowSuccess] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const dataToSend = { ActivateToken: token }
        axios.post('https://www.pimasens.com/api/activateAccount/', dataToSend, {
        })
            .then(response => {
                const data = response.data;

                if (data.error) {
                    setError('An error occurred');
                    setShowSuccess(false)
                } else {
                    setShowSuccess(true)
                }
            })
            .catch(error => {
                setError('An error occurred');
                setShowSuccess(false)
            });
    }, []);


    return (
        <div className='ActivateAccountPage'>
            {showSuccess && (
                <div className='ActivateAccountPage-box'>
                    <h2 className='ActivateAccountPage-h2'>Account successfully activated</h2>
                    <a className='ActivateAccountPage-signin' href="/">Login</a>
                </div>
            )}
            {error && (
                <div className='ActivateAccountPage-box'>
                    <h2 className='ActivateAccountPage-h2'>Invalid URL</h2>
                </div>
            )}
        </div>
    );
};

export default ActivateAccountPage;

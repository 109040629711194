import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import SuccessPage from './pages/SuccessPage';
import ContactPage from './pages/ContactPage';
import Agrilo from './agrilo_pages/Agrilo';
import Zenro from './zenro_pages/Zenro';
import AboutUsPage from './pages/AboutUsPage';
import ProductsPage from './pages/ProductsPage';
import ZenroProductPage from './pages/ZenroProductPage';
import AgriloProductPage from './pages/AgriloProductPage';
import PricingPage from './pages/PricingPage';
import ComingSoonPage from './pages/ComingSoonPage';
import ResetEmailPage from './pages/ResetEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ActivateAccountPage from './pages/ActivateAccountPage';
import ActivateTokenPage from './pages/ActivateTokenPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage'
import ZenroProf from './zenro_prof_pages/ZenroProf';
import { el } from 'react-day-picker/locale';
import AgriloProf from './agrilo_prof_pages/AgriloProf';


const AgriloRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/home':
        document.title = 'Home - Agrilo';
        break;
      case '/login':
        document.title = 'Login - Agrilo';
        break;
      case '/sign-up':
        document.title = 'Sign Up - Agrilo';
        break;
      case '/contact-us':
        document.title = 'Contact Us - Agrilo';
        break;
      case '/pricing':
        document.title = 'Pricing - Agrilo';
        break;
      case '/products':
        document.title = 'Products - Agrilo';
        break;
      case '/about-us':
        document.title = 'About Us - Agrilo';
        break;
      default:
        document.title = 'Agrilo';
    }
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<AgriloProductPage />} />
      <Route path="/home" element={<AgriloProductPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/agrilo" element={<Agrilo />} />
      <Route path="/agrilo-prof" element={<AgriloProf />} />
      {/* Add more Agrilo-specific routes here */}
    </Routes>
  )
};
const ZenroRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/home':
        document.title = 'Home - Zenro';
        break;
      case '/login':
        document.title = 'Login - Zenro';
        break;
      case '/sign-up':
        document.title = 'Sign Up - Zenro';
        break;
      case '/contact-us':
        document.title = 'Contact Us - Zenro';
        break;
      case '/pricing':
        document.title = 'Pricing - Zenro';
        break;
      case '/products':
        document.title = 'Products - Zenro';
        break;
      case '/about-us':
        document.title = 'About Us - Zenro';
        break;
      default:
        document.title = 'Zenro';
    }
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<ZenroProductPage />} />
      <Route path="/home" element={<ZenroProductPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/zenro" element={<Zenro />} />
      <Route path="/zenroProf" element={<ZenroProf />} />
      {/* Add more Zenro-specific routes here */}
    </Routes>
  )
};


const App = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/home':
        document.title = 'Home - Pimasens';
        break;
      // case '/login':
      //   document.title = 'Login - Pimasens';
      //   break;
      // case '/sign-up':
      //   document.title = 'Sign Up - Pimasens';
      //   break;
      case '/success':
        document.title = 'Success - Pimasens';
        break;
      case '/contact-us':
        document.title = 'Contact Us - Pimasens';
        break;
      case '/pricing':
        document.title = 'Pricing - Pimasens';
        break;
      case '/products':
        document.title = 'Products - Pimasens';
        break;
      case '/zenroProductsPage':
        document.title = 'Zenro App - Pimasens';
        break;
      case '/products/agrilo':
        document.title = 'Agrilo App - Pimasens';
        break;
      case '/about-us':
        document.title = 'About Us - Pimasens';
        break;
      default:
        document.title = 'pimasens';
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      {/* <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} /> */}
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/zenroProductsPage" element={<ZenroProductPage />} />
      <Route path="/agriloProductsPage" element={<AgriloProductPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/success" element={<ComingSoonPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/Zenro" element={<Zenro />} />
      <Route path="/send" element={<ResetEmailPage />} />
      <Route path="/reset/:token" element={<ResetPasswordPage />} />
      <Route path="/activate/:token" element={<ActivateAccountPage />} />
      <Route path="/authenticationCode" element={<ActivateTokenPage />} />
      <Route path="about/about-us" element={<ComingSoonPage />} />
      <Route path="about/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/about/cookie-policy" element={<CookiePolicyPage />} />
    </Routes>
  );
};

const AppWrapper = () => {
  var subdomain = window.location.hostname.split('.');
  if (subdomain[0] === 'www') {
    subdomain = subdomain[1]
  } else {
    subdomain = subdomain[0]
  }
  return (
    <BrowserRouter>
      {subdomain === 'agrilo' && <AgriloRoutes />}
      {subdomain === 'zenro' && <ZenroRoutes />}
      {['www', 'pimasens'].includes(subdomain) && <App />}
    </BrowserRouter>
  );
};

export default AppWrapper;

import React, { useState, useEffect } from 'react';
import './AgriloProfEventPage.css';
import axios from 'axios';
import AgriloProfEventBar from './components/AgriloProfEventBar';
import AgriloProfEventBox from './components/AgriloProfEventBox';
import AgriloProfTimeBox from './components/AgriloProfTimeBox';
import AgriloProfUsersBox from './components/AgriloProfUsersBox';


const AgriloProfEventPage = ({ access, prefill }) => {

    const [info, setInfo] = useState({
        startDate: '',
        endDate: '',
        untilDate: '',
        repeat: false,
        frequency: '1',
        frequencyType: 'week',
        description: '',
        user: '',
        title: '',
    })

    const [user, setUser] = useState([]);


    return (
        <div className="AgriloProfEventPage">
            <h1 className='AgriloProfEventPage-h1'> Summary</h1>
            <div className='AgriloProfEventPage-line1' />
            <AgriloProfEventBar info={info} user={user}/>
            <AgriloProfEventBox info={info} setInfo={setInfo}/>
            <AgriloProfTimeBox info={info} setInfo={setInfo}/>
            <AgriloProfUsersBox info={info} setInfo={setInfo} access={access}/>
        </div>
    );
};

export default AgriloProfEventPage;
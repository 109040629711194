import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AgriloProfEventBox.css';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import { CiCirclePlus } from "react-icons/ci";
const AgriloProfEventBox = ({info, setInfo}) => {

    const onTitleChange = (e) => {
        setInfo({...info, title: e.target.value});
    }

    const onDescriptionChange = (e) => {
        setInfo({...info, description: e.target.value});
    }

    const onResetClick = () => {
        setInfo({...info, title: '', description: ''});
    }

    return (
        <div className="AgriloProfEventBox">
            <img src={CalendarIcon} alt="shot1" className="AgriloProfEventBox-icon" />
            <h1 className='AgriloProfEventBox-h1'>Event</h1>
            <div className='AgriloProfEventBox-line1' />
            <div className='AgriloProfEventBox-container'>
                <div className='AgriloProfEventBox-Title'>
                    <h1 className='AgriloProfEventBox-label'>Title</h1>
                    <input className='AgriloProfEventBox-input-title' value={info.title} onChange={onTitleChange}/>
                </div>
                {/* <div className='AgriloProfEventBox-Type'>
                    <h1 className='AgriloProfEventBox-label'>Type</h1>
                    <div className='AgriloProfEventBox-type-container'>
                        <div className='AgriloProfEventBox-type-add'>
                            <CiCirclePlus size={35} />
                        </div>
                    </div>
                </div> */}
                <div className='AgriloProfEventBox-Description'>
                    <h1 className='AgriloProfEventBox-label'>Description</h1>
                    <textarea className='AgriloProfEventBox-textarea-description' value={info.description} onChange={onDescriptionChange}/>
                </div>
                <button className='AgriloProfEventBox-reset' onClick={onResetClick}>Reset</button>
                {/* <button className='AgriloProfEventBox-save'>Save</button> */}
            </div>
        </div>
    );
};

export default AgriloProfEventBox;
